import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import Button from '../components/button'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import Popup from 'reactjs-popup'
import EstimateForm from '../components/estimate-form'
import { X } from 'react-feather'

const CTA = ({
  title,
  customTitle,
  customDesc,
  bgimage,
  serviceHeroImage,
  btn,
  props,
}) => {
  const CTAOuterContainer = styled.section`
    padding: 4em 2em;
    height: auto;
    background-size: cover;
    position: relative;
    @media (max-width: 992px) {
      text-align: center;
    }
    .gatsby-image-wrapper {
      &:before {
        content: '';
        position: absolute;
        height: 100%;
        width: 100%;
        background: #000;
        top: 0;
        left: 0;
        z-index: 1;
        opacity: 0.5;
      }
    }
  `

  const ContentContainer = styled.div`
    @media (min-width: 992px) {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
  `

  const Heading = styled.h2`
    margin-bottom: 0.25em;
    color: #fff;
    font-size: calc(0.75vw + 1.15em);
  `
  const Subheading = styled.p`
    color: #f2f2f2;
    margin: 0;
    font-size: calc(0.07vw + 1em);
  `

  const CTABGImage = () => (
    <GatsbyImage
      image={getImage(bgimage ? bgimage : serviceHeroImage)}
      // alt={data.blogPost.author}
      style={{
        left: '0',
        width: '100%',
        height: '100%',
        top: '0',
        zIndex: '-1',
        position: 'absolute',
      }}
    />
  )

  return (
    <CTAOuterContainer>
      {bgimage || serviceHeroImage ? <CTABGImage /> : null}

      <div className="container">
        <ContentContainer>
          <div>
            <Heading>{customTitle ? customTitle : title}</Heading>
            {customDesc ? <Subheading>{customDesc}</Subheading> : null}
          </div>

          <Popup
            trigger={
              <button
                title={'Get Started with ' + title}
                style={{ borderWidth: '0' }}
                className="button-style-primary button-style-white-outline mt-2"
              >
                {btn}
              </button>
            }
            modal
            closeOnDocumentClick
          >
            {close => (
              <div className="p-2 block">
                <button
                  style={{ borderWidth: '0', float: 'right' }}
                  className="cursor-pointer d-block border-radius mt-3 mr-4 p-2 float-right m0-auto m-0-auto button__ButtonOuter-gFbSyU"
                  onClick={() => {
                    close()
                  }}
                >
                  <X color={'#333'} strokeWidth={'3'} className="d-block" />
                </button>
                <div className="p-3 py-4 block">
                  <h2
                    className="mb-1 popupheading"
                    style={{ lineHeight: '1.05' }}
                  >
                    Request A Free Estimate!
                  </h2>
                  <p
                    style={{
                      color: '#666',
                      lineHeight: '1.40',
                      fontSize: '.9em',
                    }}
                    className="mb-3 popupdesc"
                  >
                    Solid Construction &amp; Design will be happy to visit with
                    you and provide a FREE in-home estimate. You’ll get a truly
                    professional consultation for your dream home improvement
                    project. To schedule a consultation, please complete the
                    information below.
                  </p>
                  <EstimateForm
                    showServicesList={true}
                    showLabels={true}
                    showPlaceHolders={true}
                    pageLocation={
                      props.location.href ? props.location.href : 'Not provided'
                    }
                  />
                </div>
              </div>
            )}
          </Popup>
        </ContentContainer>
      </div>
    </CTAOuterContainer>
  )
}

CTA.propTypes = {
  title: PropTypes.string.isRequired,
  btn: PropTypes.string.isRequired,
}

export default CTA
