import React from 'react'

const CalloutBar = ({ calloutContent }) => {
  return (
    <div
      className={
        'callout-bar-container callout-bg-' +
        calloutContent.backgroundColor.replace(/\s+/g, '-').toLowerCase() +
        ' callout-text-color-' +
        calloutContent.textColor.replace(/\s+/g, '-').toLowerCase()
      }
    >
      <div className="content-container">
        <h2>{calloutContent.title}</h2>
      </div>
    </div>
  )
}

export default CalloutBar
