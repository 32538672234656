import { Link } from 'gatsby'
import React from 'react'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import * as Markdown from 'react-markdown'
import colors from '../styles/colors'

import Button from './button'
import Slider from 'react-slick'
import { ChevronLeft, ChevronRight } from 'react-feather'

import Usa from '../assets/icons/usa'
import Hardhat from '../assets/icons/hard-hat'
import Boxusd from '../assets/icons/boxusd'
import HeroRatingStars from './hero-rating-stars'

const HeroSection = ({
  heroImage,
  heroTitle,
  description,
  primaryActionBtnLink,
  primaryActionBtnLabel,
  standAloneImage,
  sliderVariant,
}) => (
  <section className={'hero-section hero-Stacked'}>
    <GatsbyImage
      image={getImage(heroImage)}
      className="heroAreaImg"
      alt={heroTitle}
    />
    <div className="ct-container container">
      <div className="ct-row row">
        <div className="ct-col col-12 col-md-6">
          <h3 className="hero-heading before:background-secondary">
            {heroTitle}
          </h3>
          {sliderVariant ? (
            <>
              <div className="h-description">
                {description.description ? (
                  <Markdown source={description.description} />
                ) : (
                  ''
                )}
                {primaryActionBtnLink ? (
                  <Button
                    className={'hero-area-primary-btn'}
                    to={primaryActionBtnLink}
                    color={'secondary'}
                  >
                    {primaryActionBtnLabel}
                  </Button>
                ) : null}
              </div>
              <HeroRatingStars />
            </>
          ) : (
            <>
              {description.description ? (
                <Markdown source={description.description} />
              ) : null}
              {primaryActionBtnLink ? (
                <Button
                  className={'hero-area-primary-btn'}
                  to={primaryActionBtnLink}
                  color={'secondary'}
                >
                  {primaryActionBtnLabel}
                </Button>
              ) : null}
            </>
          )}
        </div>
        {standAloneImage && (
          <div className="standalone-img d-none d-md-block col-12 col-md-6">
            <GatsbyImage
              image={getImage(heroImage)}
              className="heroAreaImg"
              alt={heroTitle}
            />
          </div>
        )}
      </div>
    </div>
  </section>
)

const NextArrow = props => {
  const { onClick } = props

  //auto scroll
  //setInterval(() => onClick(), 10000)

  return (
    <div
      className="h-arrows icon-container carousel-icon-left PrevNextArrowsContainer"
      onClick={onClick}
    >
      <ChevronLeft size={26} />
    </div>
  )
}
const PrevArrow = props => {
  const { onClick } = props
  return (
    <div
      className="h-arrows icon-container carousel-icon-right PrevNextArrowsContainer"
      onClick={onClick}
    >
      <ChevronRight size={26} />
    </div>
  )
}

const settings = {
  dots: false,
  infinite: true,
  autoplay: true,
  autoplaySpeed: 8000,
  pauseOnHover: false,
  speed: 300,
  slidesToShow: 1,
  slidesToScroll: 1,
  fade: true,
  cssEase: 'linear',
  className: 'disable-interaction-on-non-active relative',
  nextArrow: <PrevArrow />,
  prevArrow: <NextArrow />,
}

const HeroArea = ({ heroContent }) => {
  let isMultiSlide = false

  if (heroContent.length || heroContent.length > 2) {
    isMultiSlide = true
  }

  const isBuildingHTML = typeof window === 'undefined'

  if (isMultiSlide) {
    return (
      <div
        className={
          'hero-slider-container ' + (isBuildingHTML ? 'server-rendered' : null)
        }
      >
        <Slider {...settings}>
          {heroContent.map((item, index) => (
            <HeroSection key={index} {...item} sliderVariant />
          ))}
        </Slider>
      </div>
    )
  } else {
    return <HeroSection {...heroContent} standAloneImage />
  }
}

export default HeroArea
