import React from 'react'
import { graphql } from 'gatsby'
import Layout from '../components/layout'
import SEO from '../components/seo'
import HeroArea from '../components/hero-area'
import CalloutBar from '../components/callout-bar'
import Card from '../components/card'
import styled from 'styled-components'
import FeaturedItems from '../components/featured-items'
import { TestimonialSection } from '../components/testimonial_video'

import CTA from '../components/cta-bar'
import SocialReviewsFullwidth from '../components/social-reviews-fullwidth'

class IndexPage extends React.Component {
  render() {
    const homePageNode = this.props.data.allContentfulPage.edges[0].node

    const SidebarColumn = () => {
      const sideBarCards = homePageNode.sidebar.map((sidebar, index) => (
        <Card key={index} sidebar={sidebar} />
      ))

      return sideBarCards
    }
    const { testimonials: testimonial_video_posts } =
    homePageNode.testimonialSection || { testimonials: [] }
    return (
      <Layout
        isHomePage={true}
        pageProps={this.props}
        footerLogoShowcase={homePageNode.footerLogoShowcase}
      >
        <SEO
          title={homePageNode.metaTitle}
          description={homePageNode.metaDescription}
          keywords={['gatsby', 'application', 'react']}
        />

        {homePageNode.heroAreaCarousel || homePageNode.heroArea ? (
          <HeroArea
            heroContent={
              homePageNode.heroAreaCarousel
                ? homePageNode.heroAreaCarousel
                : homePageNode.heroArea
            }
          />
        ) : null}
        {homePageNode.calloutBar ? (
          <CalloutBar calloutContent={homePageNode.calloutBar} />
        ) : (
          ''
        )}
        <SocialReviewsFullwidth modalLinkTo={`/reviews/all/`} />
        {homePageNode.featuredItems ? (
          <div className="mt-0">
            <FeaturedItems data={homePageNode.featuredItems} />
          </div>
        ) : (
          ''
        )}

        {homePageNode && homePageNode.showReputationawardsSection ? (
          <section className="aboutussection shadow-md">
            <div className="container">
              <div className="row">
                <div
                  className={
                    'main-container' +
                    (homePageNode.sidebar ? 'col-12' : 'col-12')
                  }
                >
                  {homePageNode &&
                  homePageNode.featuredContent &&
                  homePageNode.featuredContent.featuredContent ? (
                    <div
                      dangerouslySetInnerHTML={{
                        __html: homePageNode.featuredContent.featuredContent,
                      }}
                    />
                  ) : (
                    ''
                  )}
                </div>
              </div>
            </div>
          </section>
        ) : null}
      <TestimonialSection testimonial_video_posts={testimonial_video_posts} />

        <div className="container main-content">
          <div className="row">
            <div
              className={
                'main-container' +
                (homePageNode.sidebar ? 'col-12 col-md-8' : 'col-12')
              }
              dangerouslySetInnerHTML={{ __html: homePageNode.body.body }}
            />

            {homePageNode.sidebar ? (
              <div className="sb-container col-12 col-md-4">
                <SidebarColumn />
              </div>
            ) : (
              ''
            )}
          </div>
        </div>

        <CTA
          title={'Start Your Home Remodeling Project Today!'}
          customTitle={homePageNode.footerCallToActionHeading}
          customDesc={homePageNode.footerCallToActionDesc}
          bgimage={homePageNode.footerCallToActionImg}
          serviceHeroImage={homePageNode.heroImage}
          btn={'Get Started!'}
          props={this.props}
        />
      </Layout>
    )
  }
}

export default IndexPage

export const pageQuery = graphql`
  query {
    allContentfulPage(filter: { slug: { eq: "home" } }) {
      edges {
        node {
          featuredItems {
            id
            serviceTitle
            featuredLabel
            excerpt
            shortExcerpt
            slug
            heroImage {
              gatsbyImageData(width: 370, placeholder: BLURRED)
            }
          }

          id
          slug
          pageTitle
          metaTitle
          metaRobotsContent
          metaDescription
          showReputationawardsSection
          featuredContent {
            featuredContent
          }
          body {
            body
          }
          footerLogoShowcase
          calloutBar {
            id
            title
            description
            backgroundColor
            textColor
          }
          footerCallToActionHeading
          footerCallToActionDesc
          footerCallToActionImg {
            gatsbyImageData(width: 1800, placeholder: BLURRED)
          }
          heroArea {
            heroTitle
            description {
              description
            }
            primaryActionBtnLabel
            primaryActionBtnLink
            heroImage {
              gatsbyImageData(width: 1500, placeholder: BLURRED)
            }
          }
          heroAreaCarousel {
            heroTitle
            description {
              description
            }
            primaryActionBtnLabel
            primaryActionBtnLink
            heroImage {
              gatsbyImageData(width: 1400, placeholder: BLURRED, quality: 100)
            }
          }
          testimonialSection {
            testimonials {
              id
              title
              projectReference {
                slug
              }
              video {
                id
                title
                file {
                  url
                  contentType
                }
              }
              thumbnail {
                gatsbyImageData(width: 600, quality: 90, placeholder: NONE)
              }
            }
          }
          sidebar {
            id
            title
            content {
              id
              content
            }
            cssClasses
          }
        }
      }
    }
  }
`